import Router from 'next/router';

import { ROLE } from '../constants/middleware';

export const isProfileComplete = (user) => {
  if (
    [ROLE.VENDOR, ROLE.ORGANISATION].includes(user?.me?.role) &&
    !user?.me?.is_profile_complete
  ) {
    Router.push('/profile');

    return false;
  }

  if (
    [ROLE.CUSTOMER].includes(user?.me?.role) &&
    !user?.me?.is_profile_complete
  ) {
    Router.push('/');

    return false;
  }
  // if (user?.me?.role == ROLE.VENDOR && !user?.me?.is_profile_complete) {
  //   Router.push('/profile');

  //   return false;
  // }

  // if (user?.me?.role == ROLE.ORGANISATION && !user?.me?.is_profile_complete) {
  //   Router.push('/organisation/profile');

  //   return false;
  // }

  return true;
};
