import React, { Fragment, useEffect } from 'react';
import { AppProps } from 'next/app';
import Amplify from '@aws-amplify/core';
import Auth from '@aws-amplify/auth';
import theme from '../assets/jss/theme';
import { CssBaseline } from '@mui/material';
import { ThemeProvider } from '@mui/material/styles';
import dynamic from 'next/dynamic';
import 'nprogress/nprogress.css';
import '../assets/styles/global.css';
import { ApolloProvider } from '@apollo/client';
import { useApollo } from '../frontend/graphQL/apollo';
import OrderNotification from '../frontend/components/shared/OrderNotification';
import * as FullStory from '@fullstory/browser';
import useMiddlewares from '../frontend/core/useMiddleware';
import Script from 'next/script';
import Head from 'next/head';

// it could be your App.tsx file or theme file that is included in your tsconfig.json
// import { Theme } from '@mui/material/styles';

// declare module '@mui/styles/defaultTheme' {
//   // eslint-disable-next-line @typescript-eslint/no-empty-interface (remove this line if you don't have the rule enabled)
//   interface DefaultTheme extends Theme {}
// }

Amplify.configure({
  Auth: {
    region: process.env.AWS_REGION,
    userPoolId: process.env.AWS_USER_POOL_ID,
    userPoolWebClientId: process.env.AWS_USER_POOL_CLIENT_ID,
    cookieStorage: {
      domain: process.env.AWS_AUTH_COOKIE_DOMAIN || 'localhost',
      path: '/',
      expires: 7,
      secure: false,
    },
  },
});

Auth.configure({
  oauth: {
    domain: process.env.AWS_IDP_DOMAIN,
    scope: [
      'phone',
      'email',
      'profile',
      'openid',
      'aws.cognito.signin.user.admin',
    ],
    responseType: 'token',
    redirectSignIn: process.env.AWS_COGNITO_SOCIAL_REDIRECT_SIGNIN,
    redirectSignOut: process.env.AWS_COGNITO_SOCIAL_REDIRECT_SIGNOUT,
  },
});

const TopProgressBar = dynamic(
  () => {
    return import('../frontend/components/TopProgressBar');
  },
  { ssr: false },
);

const FullStoryProvider = () => {
  const { user } = useMiddlewares();

  useEffect(() => {
    // Init Fullstory record
    if (process.env.NODE_ENV === 'production') {
      console.log('record');
      FullStory.init({ orgId: process.env.NEXT_PUBLIC_ORG_ID });
    }
  }, []);

  useEffect(() => {
    // Init Fullstory identify
    if (process.env.NODE_ENV === 'production' && user && user.me) {
      console.log('identify');
      const { email, full_name, role } = user.me;

      FullStory.setUserVars({
        displayName: full_name,
        email,
        role,
      });
    }
  }, [user]);

  return <Fragment />;
};

function App({ Component, pageProps }: AppProps) {
  const apolloClient = useApollo(pageProps.initialApolloState);

  useEffect(() => {
    // Remove the server-side injected CSS.
    const jssStyles = document.querySelector('#jss-server-side');
    if (jssStyles) {
      jssStyles.parentElement.removeChild(jssStyles);
    }
  }, []);

  useEffect(() => {
    if ('serviceWorker' in navigator) {
      window.addEventListener('load', () => {
        navigator.serviceWorker
          .register('/serviceWorker.js')
          .then((registration) => {
            if (registration) {
              return;
            }
          })
          .catch((_err) => {
            return;
          });
      });
    }
  }, []);

  return (
    <>
      <Head>
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1, maximum-scale=1"
        />
      </Head>
      <Script
        strategy="lazyOnload"
        src={`https://www.googletagmanager.com/gtag/js?id=${process.env.NEXT_PUBLIC_GA_ID}`}
      />

      <Script
        id="google-analytics-script"
        strategy="lazyOnload"
        nonce="2726c7f26c"
      >
        {`
          window.dataLayer = window.dataLayer || [];
          function gtag(){dataLayer.push(arguments);}
          gtag('js', new Date());
          gtag('config', '${process.env.NEXT_PUBLIC_GA_ID}', {
          page_path: window.location.pathname,
          });
    `}
      </Script>
      <FacebookPixelScript />

      <ThemeProvider theme={theme}>
        <TopProgressBar />
        <CssBaseline />
        <ApolloProvider client={apolloClient}>
          <FullStoryProvider />
          <OrderNotification />
          <Component {...pageProps} />
        </ApolloProvider>
      </ThemeProvider>
    </>
  );
}

export default App;

const FacebookPixelScript = () => (
  <React.Fragment>
    <Script
      strategy="lazyOnload"
      nonce="2726c7f26c"
      dangerouslySetInnerHTML={{
        __html: `!function(f,b,e,v,n,t,s)
    {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
    n.callMethod.apply(n,arguments):n.queue.push(arguments)};
    if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
    n.queue=[];t=b.createElement(e);t.async=!0;
    t.src=v;s=b.getElementsByTagName(e)[0];
    s.parentNode.insertBefore(t,s)}(window, document,'script',
    'https://connect.facebook.net/en_US/fbevents.js');
    fbq('init', '4887706604618573');
    fbq('track', 'PageView');`,
      }}
    />
    <noscript
      dangerouslySetInnerHTML={{
        __html: `<img height="1" width="1" style="display:none"
    src="https://www.facebook.com/tr?id=4887706604618573&ev=PageView&noscript=1" />`,
      }}
    />
  </React.Fragment>
);
