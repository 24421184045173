import Router from 'next/router';

export const isEmailVerified = (user) => {
  if (typeof user?.me?.is_verified != 'undefined' && !user?.me?.is_verified) {
    Router.push('/account/change-email');

    return false;
  }
  return true;
};
