import gql from 'graphql-tag';

export const POST_CREATED_SUBSCRIPTION = gql`
  subscription {
    postCreated {
      followerIds
    }
  }
`;

export const ORDER_CREATED_SUBSCRIPTION = gql`
  subscription {
    orderCreated {
      id
      name
      description
      price
      is_on_sale
      sale_price
      quantity
      tag_ids
      medias {
        id
        public_url
        file_type
        images {
          vendor_products
        }
      }
      store {
        username
      }
      created_at
      order_product_id
      order_product_created_at
    }
  }
`;
