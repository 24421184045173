import Auth from '@aws-amplify/auth';
import { Hub } from '@aws-amplify/core';
import { useEffect, useState } from 'react';

export interface UseAuthHookResponse {
  currentUser: any;
  signIn: () => void;
  signOut: () => void;
}

const getCurrentUser = async () => {
  try {
    return {
      user: await Auth.currentAuthenticatedUser(),
      isLoggedIn: true,
    };
  } catch {
    return {
      user: null,
      isLoggedIn: false,
    };
  }
};

const useCognitoAuth = (): UseAuthHookResponse => {
  const [currentUser, setCurrentUser] = useState(null);

  useEffect(() => {
    const updateUser = async () => {
      const data = await getCurrentUser();
      // console.log(data.user?.signInUserSession?.accessToken?.jwtToken);
      if (!localStorage.getItem('ACCESS_TOKEN') && data.isLoggedIn) {
        localStorage.setItem(
          'ACCESS_TOKEN',
          data.user?.signInUserSession?.accessToken?.jwtToken,
        );
      }
      setCurrentUser(data);
    };
    Hub.listen('auth', updateUser);
    updateUser();
    return () => Hub.remove('auth', updateUser);
  }, []);

  const signIn = () => Auth.federatedSignIn();

  const signOut = () => Auth.signOut();

  return { currentUser, signIn, signOut };
};

export default useCognitoAuth;

export { getCurrentUser };
