import Router from 'next/router';

import { ROLE } from '../constants/middleware';

export const isVendor = (user) => {
  if ([ROLE.CUSTOMER, ROLE.ORGANISATION].includes(user?.me?.role)) {
    Router.push('/');

    return false;
  }
  return true;
};
