export default {
  fontSize: 14,
  fontFamily: ['Space Grotesk', 'Roboto', 'Helvetica Neue'].join(','),
  h1: {
    fontWeight: 500,
    fontSize: 26,
  },
  h2: {
    fontWeight: 600,
    fontSize: 24,
  },
  h3: {
    fontWeight: 600,
    fontSize: 20,
  },
  h4: {
    fontWeight: 500,
    fontSize: 18,
  },
  h5: {
    fontWeight: 500,
    fontSize: 16,
  },
  h6: {
    fontWeight: 500,
    fontSize: 14,
  },
  overline: {
    fontWeight: 500,
  },
};
