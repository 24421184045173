import { useEffect } from 'react';
import { useQuery } from '@apollo/client';

import {
  IS_PROFILE_COMPETE,
  IS_VENDOR,
  IS_CUSTOMER,
  IS_LOGGEDIN,
  ROLE,
  IS_EMAIL_VERIFIED,
  IS_ORGANISATION,
  IS_VENDOR_AND_ORGANISATION,
} from './constants/middleware';
import { isLoggedIn } from './middlewares/isLoggedIn';
import { isCustomer } from './middlewares/isCustomer';
import { isOrganisation } from './middlewares/isOrganisation';
import { isEmailVerified } from './middlewares/isEmailVerified';
import { isVendor as isVendorMiddleware } from './middlewares/isVendor';
import { isVendorAndOrganisation } from './middlewares/isVendorAndOrganisation';
import { GET_USER_DETAILS } from '../../frontend/graphQL/queries/user';
import { isProfileComplete } from './middlewares/isProfileComplete';
import useCognitoAuth from './useCognitoAuth';

/**
 * Use middlewares
 *
 * @param middlewares
 * @returns
 */
export default function useMiddlewares(midddlewares = []) {
  const { data: user = {}, loading } = useQuery(GET_USER_DETAILS, {
    fetchPolicy: 'no-cache',
  });

  const { currentUser } = useCognitoAuth();

  const registerMiddlewares = {
    [IS_CUSTOMER]: isCustomer,
    [IS_ORGANISATION]: isOrganisation,
    [IS_VENDOR]: isVendorMiddleware,
    [IS_PROFILE_COMPETE]: isProfileComplete,
    [IS_LOGGEDIN]: isLoggedIn,
    [IS_EMAIL_VERIFIED]: isEmailVerified,
    [IS_VENDOR_AND_ORGANISATION]: isVendorAndOrganisation,
  };

  useEffect(() => {
    if (midddlewares.length == 0) return;
    if (currentUser) {
      midddlewares.every((middleware) => {
        if (middleware in registerMiddlewares) {
          return registerMiddlewares[middleware](user, currentUser);
        }
      });
    }
  }, [user, currentUser]);

  return {
    user,
    loadingUser: loading,
    isLoggedIn: currentUser?.isLoggedIn,
    isVendor: user?.me?.role == ROLE.VENDOR,
    isOrganisation: user?.me?.role == ROLE.ORGANISATION,
    isCustomer: user?.me?.role == ROLE.CUSTOMER,
  };
}
