import Router from 'next/router';
import * as storage from '../../utils/storage';

export const isLoggedIn = (_user, currentUser) => {
  if (!currentUser.isLoggedIn) {
    storage.set('intended_url', Router.asPath);
    Router.push('/login');
    return false;
  }

  return true;
};
