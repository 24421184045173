import Router from 'next/router';

import { ROLE } from '../constants/middleware';

export const isOrganisation = (user) => {
  if (user?.me?.role == ROLE.VENDOR) {
    Router.push('/vendor');

    return false;
  }

  if (user?.me?.role == ROLE.CUSTOMER) {
    Router.push('/');

    return false;
  }

  return true;
};
