import { split } from '@apollo/client';
import { WebSocketLink } from '@apollo/client/link/ws';
import { createUploadLink } from 'apollo-upload-client';
import { setContext } from '@apollo/client/link/context';
import { getMainDefinition } from '@apollo/client/utilities';
import { ApolloClient, InMemoryCache } from '@apollo/client';
import { ErrorResponse, onError } from '@apollo/client/link/error';
import { Auth } from 'aws-amplify';
import promiseToObservable from './promiseToObservable';
import { SubscriptionClient } from 'subscriptions-transport-ws';

const url = process.env.API_URL;
const wsUrl = process.env.WS_API_URL;

const httpLink = createUploadLink({
  uri: url + '/api/graphql',
});

const authLink = setContext((_, { headers }) => {
  // get the authentication token form local store if it exists
  const token = localStorage.getItem('ACCESS_TOKEN');

  if (token) {
    return {
      headers: {
        ...headers,
        authorization: token ? `Bearer ${token}` : '',
      },
    };
  }

  // return the headers to the context so httpLink can read them
  return {
    headers: {
      ...headers,
    },
  };
});

interface Definintion {
  kind: string;
  operation?: string;
}

const refreshToken = async () => {
  const cognitoUser = await Auth.currentAuthenticatedUser();
  const currentSession = await Auth.currentSession();

  return new Promise((resolve, reject) => {
    cognitoUser.refreshSession(
      currentSession?.getRefreshToken(),
      (err, session) => {
        if (err) {
          reject(err);
        }

        resolve(session?.accessToken?.jwtToken);
      },
    );
  });
};

const errorControl = onError(
  ({ graphQLErrors, networkError, operation, forward }: ErrorResponse) => {
    if (graphQLErrors && localStorage.getItem('ACCESS_TOKEN')) {
      const [err] = graphQLErrors;

      switch (err.extensions.code) {
        case 'UNAUTHENTICATED':
          return promiseToObservable(refreshToken()).flatMap(
            (accessToken: any) => {
              if (accessToken) {
                const oldHeaders = operation.getContext().headers;

                operation.setContext({
                  headers: {
                    ...oldHeaders,
                    authorization: 'Bearer ' + accessToken,
                  },
                });
                localStorage.setItem('ACCESS_TOKEN', accessToken);

                return forward(operation);
              }
            },
          ) as any;
      }
    }

    if (networkError) {
      //console.log(`[Network error]: ${networkError}`);
    }
  },
);

let wsLink = null;

if (process.browser) {
  //const token = localStorage.getItem('ACCESS_TOKEN');
  //if (token) {
  const wsClient = new SubscriptionClient(`${wsUrl}/api/graphqlSubscriptions`, {
    reconnect: true,
    lazy: true,
    // connectionParams: {
    //   authToken: localStorage.getItem('ACCESS_TOKEN'),
    // },
    connectionParams: async () => {
      const data = await Auth.currentAuthenticatedUser();
      const accessToken = data?.signInUserSession?.accessToken?.jwtToken;

      return {
        headers: {
          authorization: accessToken ? `Bearer ${accessToken}` : '',
        },
      };
    },
  });

  wsLink = new WebSocketLink(wsClient);
  //}
  // const subscriptionAuthMiddleware = {
  //   applyMiddleware: async (options, next) => {
  //     console.log(options, localStorage.getItem('ACCESS_TOKEN'));
  //     options.authToken = localStorage.getItem('ACCESS_TOKEN');
  //     next();
  //   },
  // };
  // wsLink.subscriptionClient.use([subscriptionAuthMiddleware]);
}

let link = httpLink;

if (process.browser) {
  link = split(
    ({ query }) => {
      const { kind, operation }: Definintion = getMainDefinition(query);
      return kind === 'OperationDefinition' && operation === 'subscription';
    },
    wsLink,
    errorControl.concat(authLink.concat(httpLink)),
  );
}

const createApolloClient = () => {
  return new ApolloClient({
    ssrMode: typeof window === 'undefined',
    link: link,
    cache: new InMemoryCache(),
  });
};

export default createApolloClient;
