export const ROLE = {
  VENDOR: 'vendor',
  CUSTOMER: 'customer',
  ORGANISATION: 'organisation',
};

/**
 * Middlewares constant.
 */
export const IS_PROFILE_COMPETE = 'IS_PROFILE_COMPETE';
export const IS_VENDOR = 'IS_VENDOR';
export const IS_ORGANISATION = 'IS_ORGANISATION';
export const IS_CUSTOMER = 'IS_CUSTOMER';
export const IS_VENDOR_AND_ORGANISATION = 'IS_VENDOR_AND_ORGANISATION';
export const IS_LOGGEDIN = 'IS_LOGGEDIN';
export const IS_EMAIL_VERIFIED = 'IS_EMAIL_VERIFIED';
