import gql from 'graphql-tag';

export const GET_USER_DETAILS = gql`
  query {
    me {
      id
      store_id
      email
      role
      user_main_role
      is_profile_complete
      full_name
      is_verified
      created_at
      avatar
      source
      currency
      interestIds
    }
  }
`;
