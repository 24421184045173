import Router from 'next/router';

import { ROLE } from '../constants/middleware';

export const isCustomer = (user) => {
  if ([ROLE.VENDOR, ROLE.ORGANISATION].includes(user?.me?.role)) {
    Router.push('/vendor');

    return false;
  }

  return true;
};
