import { makeStyles } from '@mui/styles';
import { useSubscription } from '@apollo/client';
import { Grid, Snackbar } from '@mui/material';
import MuiAlert, { AlertProps } from '@mui/lab/Alert';
import { useState } from 'react';
import { ORDER_CREATED_SUBSCRIPTION } from '../../../graphQL/subscriptions/posts';

function Alert(props: AlertProps) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const useStyles = makeStyles(() => ({
  center: {
    textAlign: 'center',
  },
}));

const OrderNotification = () => {
  const [showSuccessMsg, setShowSuccessMsg] = useState(true);

  const handleAlertClose = () => {
    setShowSuccessMsg(false);
  };
  const { loading, data } = useSubscription(ORDER_CREATED_SUBSCRIPTION);
  const classes = useStyles();

  const Notification = () => {
    if (!loading) {
      if (data?.orderCreated.length > 0) {
        return (
          <Snackbar
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'center',
            }}
            open={showSuccessMsg}
            autoHideDuration={3000}
            onClose={handleAlertClose}
          >
            <Alert severity="info" onClose={handleAlertClose}>
              New Order Available
            </Alert>
          </Snackbar>
        );
      }
    }

    return null;
  };

  return (
    <Grid className={classes.center}>
      <Notification />
    </Grid>
  );
};

export default OrderNotification;
