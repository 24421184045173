import { createTheme } from '@mui/material/styles';

import typography from './typography';
import palette from './palette';
import overrides from './overrides';

// it could be your App.tsx file or theme file that is included in your tsconfig.json
import { Theme } from '@mui/material/styles';
import MuiTypography from './overrides/MuiTypography';
import MuiInputBase from './overrides/MuiInput';
import MuiInputLabel from './overrides/MuiInputLabel';
import MuiButton from './overrides/MuiButton';

declare module '@mui/styles' {
  // eslint-disable-next-line @typescript-eslint/no-empty-interface (remove this line if you don't have the rule enabled)
  interface DefaultTheme extends Theme {}
}

// We will be adding MUI theme related changes in here.
const theme = createTheme({
  palette,
  typography,
  components: {
    MuiButton: { 
      styleOverrides: {
        root: {
          fontWeight: 400,
          textDecoration: 'none',
          borderRadius: 20,
          color: 'black',
          fontSize: '1rem',
          textTransform: 'none',
      
          // This doesnt seems to be working with nextjs app
          // textTransform: 'none'
        },
      }
    },
    MuiFab: {
      styleOverrides: {
        root: {
          color: 'black',
        }
      }
    },
    // MuiIconButton: {
    //   styleOverrides: {
    //     root: {
    //       color: 'black',
    //     }
    //   }
    // },

    // MuiButtonBase: {
    //   styleOverrides: {
    //     root: {
    //       fontWeight: 400,
    //       textDecoration: 'none',
    //       borderRadius: 20,
    //       //color: 'black',
    //       fontSize: '1rem',
    //       textTransform: 'none',
    //       color: 'red',
      
    //       // This doesnt seems to be working with nextjs app
    //       // textTransform: 'none'
    //     },
    //   },
    // },
  



     MuiPaper: { 
      styleOverrides: {
        elevation1: {
          boxShadow: '0 0 0 1px rgba(63,63,68,0.05), 0 1px 3px 0 rgba(63,63,68,0.15)',
        },
      }
    },
     MuiTypography: { styleOverrides: MuiTypography },
     MuiInputBase: { styleOverrides: MuiInputBase },
     MuiInputLabel: { styleOverrides: MuiInputLabel },
  },
});


export default theme;
